// ===============================|| Blank Layout ||=============================== //
<script setup lang="ts">
import { RouterView } from "vue-router";
const title = ref("Umusanzu");
useHead({
  meta: [{ content: title }],
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `New Life Aged and Disability Care`
      : "New Life Aged and Disability Care";
  },
});
</script>
<template>
  <v-app>
    <RouterView />
  </v-app>
</template>
